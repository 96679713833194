import React, { Component } from "react";
import { gravatar } from "../lib/utils";

const UserAvatar = (props) => {
  const { user } = props;

  return (
    <div className="user__avatar">
      {user.gravatar_hash && (
        <img src={gravatar(user.gravatar_hash)} alt={user.fullName} />
      )}
      {!user.gravatar_hash && user.profile_image_url && (
        <img src={user.profile_image_url} alt={user.fullName} />
      )}
    </div>
  );
};

export default UserAvatar;
