import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { toggleDrawer } from 'lib/reducers';
import { ReactComponent as CloseModalIcon } from 'imgs/svg/md-close.svg';

const mapStateToProps = ({ drawer }) => ({
  drawer,
});

class Drawer extends PureComponent {
  handleClose() {
    this.props.dispatch(toggleDrawer());
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  render() {
    const { title, children, drawer, side } = this.props;
    return (
      <div
        className={`drawer--${side} drawer--open-${side} drawer--fixed-header  ${
          drawer ? 'drawer--is-visible' : ''
        }`}
      >
        <div className="drawer__content">
          <header className="drawer__header">
            {title && <h4 className="truncate">{title}</h4>}
            <button
              onClick={() => this.handleClose()}
              className="reset drawer__close-btn"
            >
              <CloseModalIcon className="icon" alt="close drawer" />
            </button>
          </header>
          <div className="drawer__body">
            {children && <div className="text-component">{children}</div>}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(Drawer);
