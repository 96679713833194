import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import Project from './Project';
import SearchInput from './SearchInput';

const mapStateToProps = ({ projects, currentProject }) => ({
  projects,
  currentProject,
});

class ProjectList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { search: null, scroll: false };
    this.refsCollection = [];
  }

  handleFilter(search) {
    this.setState({ search });
  }

  onSelected(projectRef) {
    const search = null;
    this.setState({ search, scroll: true });
    setTimeout(() => {
      this.scrollToSelection(projectRef);
    }, 250);
  }

  scrollToSelection(projectRef) {
    try {
      this.domNode.scrollTop = projectRef.offsetTop - this.domNode.offsetTop;
      setTimeout(() => {
        this.setState({ scroll: false });
      }, 1000);
    } catch (e) {
      console.error(e);
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.currentProject && !this.state.scroll) {
      const targetRef = this.refsCollection[newProps.currentProject.id];
      setTimeout(() => {
        this.scrollToSelection(targetRef);
      }, 0);
    }
  }

  render() {
    const { projects: all } = this.props;
    const { search } = this.state;
    const projects = search
      ? all.filter((p) => p.name.toLowerCase().includes(search.toLowerCase()))
      : all;
    return (
      <div className="projects">
        <div className="projects__search">
          <SearchInput onSearch={this.handleFilter.bind(this)} />
        </div>
        <ul className="projects__list" ref={(r) => (this.domNode = r)}>
          {projects.map((p, i) => {
            return (
              <li
                ref={(r) => {
                  this.refsCollection[p.id] = r;
                }}
                key={p.id}
              >
                <Project project={p} onSelected={this.onSelected.bind(this)} />
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}

export default connect(mapStateToProps)(ProjectList);
