import moment from 'moment';
import md5 from 'md5';
import { labels } from 'labels';
import { isObject } from 'lodash';
export const WORKING_DAYS = labels.work_days.split(',');
export const WORKING_HOURS = [9, 10, 11, 12, 14, 15, 16, 17];

const defaultColor = 'limegreen;';
export const chalk = (color = defaultColor) => (msg, ...args) => {
  console.log(`%c ${msg}`, `color:${color}`, args);
  return;
};

export function log(msg, ...args) {
  if (process.env.REACT_APP_ENV === 'development') {
    console.log(`%c ${msg}`, `color:${defaultColor}`, ...args);
  }
  return;
}

export const getMeetingUrl = (data) => {
  const { note, project, startDate } = data;
  const text = `${project} ${note ? '-' + note : ''}`;
  const start = moment(startDate);
  const fmt = 'YYYYMMDDTHHmm';
  const dates = `${start.format(fmt)}/${start.add(1, 'hour').format(fmt)}`;
  const url = `https://calendar.google.com/calendar/r/eventedit?text=${text}&dates=${dates}`;
  log('event url', url);
  //20201208T160000/20201208T180000
  return url;
};

export const gravatar = (hash) =>
  `https://www.gravatar.com/avatar/${hash}?s=120`;

export const getGravatarHash = (email) => md5(email.toLowerCase());

// Sleep in async functions. Use: await sleep(2000).
export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const convertColor = (hue) => {
  return hue;

  // if (!hue) return null;
  // const s = "70%";
  // const l = "80%";
  // const rgb = hexToRgb(hue);
  // const h = getHue(rgb.r, rgb.g, rgb.b);
  // const color = `hsl(${h}, ${s}, ${l})`;
  // return color;
};

// Random hex color
export const generateColor = () =>
  `#${Math.floor(Math.random() * 16777215).toString(16)}`;

//  Remove any duplicates from an array of primitives.
export const unique = (arr) => [...new Set(arr)];

export const getWeekId = (date) => date.format('YYYY[W]WW');

// LOCAL STORAGE
export const loadKey = (k) => {
  const data = window.localStorage.getItem(k);
  return data ? JSON.parse(data) : null;
};

export const persistKey = (key, data) => {
  if (data) {
    window.localStorage.setItem(key, JSON.stringify(data));
  } else {
    window.localStorage.removeItem(key);
  }
};

export const playSound = (sound) => {
  const audio = new Audio(sound);
  audio.volume = 0.1;
  audio.currentTime = 0;
  audio.play();
};

// CONSOLE LOG
export const greet = () => {
  const message = `%c

 Hi there, fellow developer! Thanks for visiting.
 If you're an aspiring developer, startup-er,
 or business owner, make sure you check out our
 website for info about us and inspiration!

 Link: https://www.leanpanda.com

 We'd love to hear what you think!

 @leanpanda
                           .
     ,,,         ,,,       .
   ;"   ^;     ;'   ",     .
   ;    s$$$$$$$s     ;    .
   ,  ss$$$$$$$$$$s  ,'    .
   ;s$$$$$$$$$$$$$$$       .
   $$$$$$$$$$$$$$$$$$      .
  $$$$P""Y$$$Y""W$$$$$     .
  $$$$  p"$$$"q  $$$$$     .
  $$$$  .$$$$$.  $$$$      .
    $$$$$$$$$$$$$$$        .
     "Y$$$"*"$$$Y"  Lean   .
        "$b.d$"     Panda  .
                           .
                           .
`;

  if (typeof console === 'object') {
    console.log(message, 'background: #272B34; color: #f4f4f4');
  } else {
    console.log(message);
  }
};
