import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import rootReducer from "./reducers";

// const dev = process.env.REACT_APP_ENV === 'dev'
// dev ? composeWithDevTools(applyMiddleware(thunk)) : applyMiddleware(thunk)

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk))
);

export default store;
