import moment from 'moment';
import * as api from '../api';
import * as utils from '../utils';
import { log } from '../utils';

import { loading, setWeekData, done, setProjectUpdates } from './index';

export function toggleEnabled() {}

export function getAttributes(obj) {
  const attributes = obj.map((o) => {
    if (o.data && o.data.attributes) {
      if (o.data.attributes.id) {
        o.data.attributes.id = parseInt(o.data.attributes.id);
      }
      return o.data.attributes;
    }
    return null;
  });
  return attributes;
}

export function parseCommitments(slots) {
  const commitments = slots
    .filter((s) => s.data.relationships.user.data != null)
    .map((slot) => {
      let c = slot.data.attributes;
      c.id = parseInt(c.id);
      c.date = c.when_date;
      c.user_id = parseInt(slot.data.relationships.user.data.id);
      c.project_id = parseInt(slot.data.relationships.project.data.id);
      delete c.when_date;
      delete c.updated_at;
      delete c.created_at;
      return c;
    });
  return commitments ? commitments : [];
}

export function parseAvailabilities(items) {
  const availabilities = items
    .filter((i) => i.data.relationships.user.data != null)
    .map((item) => {
      const user_id = parseInt(item.data.relationships.user.data.id);
      const slots = item.data.attributes.slot_availabilities.map(
        (a) => a.attributes
      );
      return { user_id, slots };
    });
  return availabilities;
}

function transofrmData(response) {
  // log("GET WEEK", response);
  const data = response.data.attributes;
  const { start_date, end_date } = data;
  const users = getAttributes(data.users);
  const projects = getAttributes(data.projects);
  const commitments = parseCommitments(data.slots);
  const availabilities = parseAvailabilities(data.availabilities);
  const entries = data.entries.map((e) => e.week_entry.data.attributes);
  return {
    users,
    projects,
    start_date,
    end_date,
    commitments,
    availabilities,
    entries,
  };
}

// API CALL
const getData = async (date, sync = false) => {
  const weekId = utils.getWeekId(date);
  // log('weekId', weekId)
  const response = await (sync ? api.syncWeek(weekId) : api.fetchWeek(weekId));
  return transofrmData(response);
};

//THUNKS
const getWeek = (date = null) => async (dispatch) => {
  if (!date) {
    date = moment();
  }
  try {
    dispatch(loading());
    const payload = await getData(date);
    dispatch(setWeekData(payload));
  } catch (e) {
    log('ERROR on GET WEEK', e);
    dispatch(done());
  }
};

const syncWeek = (date = null) => async (dispatch) => {
  if (!date) {
    date = moment();
  }
  try {
    dispatch(loading());
    const weekId = utils.getWeekId(date);
    await api.syncWeek(weekId);
    const payload = await getData(date, false);
    dispatch(setWeekData(payload));
  } catch (e) {
    log('ERROR on SYNC WEEK', e);
    dispatch(done());
  }
};

const setUserAvailability = (d, userId, slots) => async (dispatch) => {
  const date = moment(d);
  const weekId = utils.getWeekId(date);
  try {
    dispatch(loading());

    const payload = {
      user_id: userId,
      availability: {
        week_id: weekId,
        slots,
      },
    };
    await api.createUserAvailability(userId, payload);
    dispatch(getWeek(date));
  } catch (e) {
    log('ERROR on SYNC WEEK');
    dispatch(done());
  }
};

const changeUserStatus = (id, active) => async (dispatch) => {
  const data = { active };
  await api.updateUser(id, data);
};

const getProjectDetails = (id) => async (dispatch) => {
  try {
    dispatch(loading());
    const project_info = await api.fetchProject(id);
    const updates_results = await api.fetchUpdates(id);
    const project = project_info.data.attributes;
    const all_users_results = await api.fetchUsers();
    const all_users = all_users_results.data.map((u) => {
      return u.attributes;
    });
    const updates = updates_results.data
      .map((u) => {
        const user_id = u.relationships.user.data.id;
        return {
          ...u.attributes,
          user_id,
        };
      })
      .reverse();

    const payload = { project, updates, all_users };
    dispatch(setProjectUpdates(payload));
    dispatch(done());
  } catch (e) {
    log('ERROR on getProjectDetails');
    dispatch(done());
  }
};

export {
  getWeek,
  syncWeek,
  setUserAvailability,
  getProjectDetails,
  changeUserStatus,
};
