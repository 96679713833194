import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { closeModal } from 'lib/reducers';
import { setUserAvailability, changeUserStatus } from 'lib/reducers/thunks';
import { WORKING_DAYS, WORKING_HOURS } from 'lib/utils';
import _ from 'lodash';
import { labels } from 'labels';
import Switch from './Switch';
import { log } from 'lib/utils';

const mapStateToProps = ({ modalContent, users, week }) => ({
  modalContent,
  users,
  week,
});

const UserAvabilities = (props) => {
  const { modalContent, users, dispatch } = props;
  const user = users.find((u) => u.id === modalContent.id);
  const { active } = user;
  const [availabilities, setAvailabilities] = useState(user.availabilities);
  useEffect(() => {
    let deep = _.cloneDeep(availabilities);
    WORKING_DAYS.map((day, i) => {
      const index = i + 1;
      if (!deep[index]) {
        deep[index] = [];
      }
      return deep[index];
    });
    setAvailabilities(deep);
  }, []);

  const count = availabilities
    ? Object.keys(availabilities).reduce(
        (tot, day) =>
          tot + (availabilities[day] ? availabilities[day].length : 0),
        0
      )
    : 0;

  const handleClick = (weekday, hour, index) => {
    const deep = _.cloneDeep(availabilities);
    log('click', deep);
    if (index > -1) {
      deep[weekday].splice(index, 1);
    } else {
      deep[weekday].push({
        weekday,
        hour,
      });
    }
    setAvailabilities(deep);
  };

  const handleCancel = () => {
    dispatch(closeModal());
  };

  const handleSave = () => {
    const { modalContent, week } = props;
    log('week', week);
    const flatten = Object.keys(availabilities).reduce(
      (slots, day) => [...slots, ...availabilities[day]],
      []
    );
    dispatch(setUserAvailability(week.start, modalContent.id, flatten));
    dispatch(closeModal());
  };

  const handleSatus = () => {
    const value = !active;
    log('NEW VALUE', value);
    dispatch(changeUserStatus(user.id, value));
    dispatch(closeModal());
  };

  const renderHours = (day, index) => {
    if (!availabilities) return <div>...</div>;
    const slots = availabilities[index];

    return WORKING_HOURS.map((h) => {
      const available = slots ? slots.findIndex((s) => s.hour === h) : -1;
      return (
        <button
          onClick={() => handleClick(index, h, available)}
          key={`${day}_${h}`}
          className={`btn ${available < 0 ? '' : 'btn--accent'} btn-md`}
        >
          {h}
        </button>
      );
    });
  };

  return (
    <div className="availabilities__wrap">
      <div
        style={{
          marginBottom: 10,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <span style={{ marginRight: 5 }}>
          Current Status : {active === true ? 'ACTIVE' : 'DISABLED'}
        </span>
        <Switch
          className="btn btn--primary btn--md"
          label="Active"
          value={active}
          toggle={() => handleSatus()}
        />
      </div>
      <p>{` ${labels.tot_h} ${count}`}</p>
      <div className="availabilities_slots">
        {WORKING_DAYS.map((day, i) => {
          return (
            <div className="availabilities__day" key={day}>
              <div>{day}</div>
              <div>{renderHours(day, i + 1)}</div>
            </div>
          );
        })}
      </div>
      <div className="availabilities__footer">
        <button
          className="btn btn--subtle btn--md"
          onClick={() => handleCancel()}
        >
          {labels.action_cancel}
        </button>
        <button
          className="btn btn--primary btn--md"
          onClick={() => handleSave()}
        >
          {labels.action_save}
        </button>
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(React.memo(UserAvabilities));
