import React from "react";

//HEADER
import { ReactComponent as Dots } from "imgs/svg/md-more.svg";
import { ReactComponent as Menu } from "imgs/svg/md-menu.svg";
import { ReactComponent as Refresh } from "imgs/svg/ios-refresh.svg";
import { ReactComponent as Expand } from "imgs/svg/md-expand.svg";
import { ReactComponent as Contract } from "imgs/svg/md-contract.svg";
import { ReactComponent as Help } from "imgs/svg/ios-help-circle.svg";
import { ReactComponent as Notes } from "imgs/svg/ios-chatboxes.svg";
import { ReactComponent as Disc } from "imgs/svg/ios-disc.svg";

//OTHER
import { ReactComponent as Close } from "imgs/svg/ios-close.svg";
import { ReactComponent as Calendar } from "imgs/svg/ios-calendar.svg";

//FOOTER
import { ReactComponent as Sync } from "imgs/svg/ios-flash.svg";
import { ReactComponent as LeftArrow } from "imgs/svg/md-arrow-round-back.svg";
import { ReactComponent as RightArrow } from "imgs/svg/md-arrow-round-forward.svg";
import { ReactComponent as Note } from "imgs/svg/ios-bookmark.svg";
import { ReactComponent as Spinner } from "imgs/icons/spinner.svg";
import { ReactComponent as User } from "imgs/svg/ios-man.svg";
import { ReactComponent as Clock } from "imgs/svg/ios-clock.svg";

import { ReactComponent as Project } from "imgs/svg/ios-apps.svg";
import { ReactComponent as Link } from "imgs/svg/ios-link.svg";

import { ReactComponent as EyeOff } from "imgs/svg/ios-eye-off.svg";
import { ReactComponent as Eye } from "imgs/svg/ios-eye.svg";

import { ReactComponent as Rum } from "imgs/rum.svg";
import { ReactComponent as Mate } from "imgs/mate.svg";

const contain = {
  maxWidth: 40,
  maxHeight: 40,
};

const Icon = (props) => {
  switch (props.name) {
    case "menu":
      return <Menu {...props} style={contain} />;
    case "help":
      return <Help {...props} style={contain} />;
    case "expand":
      return <Expand {...props} style={contain} />;
    case "contract":
      return <Contract {...props} style={contain} />;
    case "close":
      return <Close {...props} style={contain} />;
    case "left_arrow":
      return <LeftArrow {...props} style={contain} />;
    case "right_arrow":
      return <RightArrow {...props} style={contain} />;
    case "sync":
      return <Sync {...props} style={contain} />;
    case "notes":
      return <Notes {...props} style={contain} />;
    case "note":
      return <Note {...props} style={contain} />;
    case "calendar":
      return <Calendar {...props} style={contain} />;
    case "spinner":
      return <Spinner {...props} style={contain} />;
    case "user":
      return <User {...props} style={contain} />;
    case "clock":
      return <Clock {...props} style={contain} />;
    case "project":
      return <Project {...props} style={contain} />;
    case "link":
      return <Link {...props} style={contain} />;
    case "more":
      return <Dots {...props} style={contain} />;
    case "eye":
      return <Eye {...props} style={contain} />;
    case "eye_off":
      return <EyeOff {...props} style={contain} />;
    case "rum":
      return <Rum {...props} style={contain} />;
    case "mate":
      return <Mate {...props} style={contain} />;
    case "refresh":
      return <Refresh {...props} style={contain} />;
    case "disc":
      return <Disc {...props} style={contain} />;
    default:
      return <div style={contain} />;
  }
};
export default Icon;
