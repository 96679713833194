import LocalizedStrings from 'react-localization';

export const labels = new LocalizedStrings({
  en: {
    title: 'Rummate',
    projects: 'Projects',
    work_days: 'Monday,Tuesday,Wednesday,Thursday,Friday',
    days: 'Monday,Tuesday,Wednesday,Thursday,Friday,Saturday,Sunday',
    months:
      'January,February,March,April,May,June,July,August,September,October,November,December',
    today: 'This week',
    prev: 'Previous week',
    next: 'Next week',
    calendar: 'cal',
    help: 'help',
    reload: 'reload',
    keyb_m: 'Toggle Menu',
    keyb_f: 'Toggle FullScreen',
    tot_h: 'Total hours',
    action_save: 'Save',
    action_cancel: 'Cancel',
    hours_available: 'Available',
    hours_committed: 'Allocate',
    hours_worked: 'Worked',
    archived: 'Archived',
    inactive: 'Inactive',
    sprint: 'Sprint',
    budget: 'Budget',
    productOwner: 'PO',
  },
  it: {
    title: 'Rummate',
    projects: 'Progetti',
    work_days: 'Lunedì,Martedì,Mercoledì,Giovedì,Venerdì',
    days: 'Lunedì,Martedì,Mercoledì,Giovedì,Venerdì,Sabato,Domenica',
    months:
      'Gennaio,Febbraio,Marzo,Aprile,Maggio,Giugno,Luglio,Agosto,Settembre,Ottobre,Novembre,Dicembre',
    today: 'Questa settimana',
    prev: 'Settimana Precedente',
    next: 'Settimana Successiva',
    calendar: 'cal',
    help: 'help',
    reload: 'reload',
    keyb_m: 'Attiva/Disattiva Menu',
    keyb_f: 'Attiva/Disattiva FullScreen',
    tot_h: 'Totale Ore',
    action_save: 'Salva',
    action_cancel: 'Annulla',
    hours_available: 'Disponibili',
    hours_committed: 'Allocate',
    hours_worked: 'Lavorate',
    archived: 'Archiviato',
    inactive: 'Inattivo',
    sprint: 'Sprint',
    budget: 'Budget',
    productOwner: 'PO',
  },
});

export const setLanguage = (code) => {
  labels.setLanguage(code);
  return labels.getLanguage();
};

export const getLanguage = () => labels.getLanguage();

export const getDeviceLang = () => labels.getInterfaceLanguage();

export const format = (label, ...replacements) =>
  labels.formatString(labels[label], ...replacements);
