import axios from 'axios';
import getToken from 'lib/cookies';
import { log } from 'lib/utils';

const baseUrl = process.env.REACT_APP_API_BASE_URL;

log('baseUrl', baseUrl);

const base_headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'Access-Control-Allow-Credentials': true,
};

// const checkErrors = async res => {
//   log('errors', res.errors)
//   const codes = res.errors.map(e => e.code)
//   log('errors code', codes)
//   throw codes.join(',')
// }

const call = async (method, path, json = null) => {
  const bearer_token = getToken();
  log('call', baseUrl + path);
  log('BEARER TOKEN', bearer_token);
  if (!bearer_token) {
    throw new Error('missing bearer');
  }
  const res = await axios({
    method,
    url: `${baseUrl}${path}`,
    headers: {
      Authorization: `Bearer ${bearer_token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Credentials': true,
    },
    data: json,
  });

  log('status', res.status);
  log('response', res);
  return res.data;
};

const get = (path) => call('get', path);
const post = (path, json) => call('post', path, json);
const patch = (path, json) => call('patch', path, json);
const del = (path, json) => call('delete', path, json);

// FAKE
export const login = (data) => post(`/authenticate`, data);
export const me = () => get(`/me`);
// GET
export const fetchWeek = (id) => get(`/weeks/${id}`);
export const fetchUsers = () => get('/users');
export const fetchUser = (userId) => get(`/users/${userId}`);
export const fetchProjects = () => get('/projects');
export const fetchProject = (project_id) => get(`/projects/${project_id}`);
export const fetchUpdates = (project_id) =>
  get(`/projects/${project_id}/updates`);

// PATCH
export const updateUpdate = (id, data) => patch(`/updates/${id}`, data);
export const updateProject = (id, data) => patch(`/projects/${id}`, data);
export const updateUser = (id, data) => patch(`/users/${id}`, data);

// POST
export const syncWeek = (id) => post(`/weeks/${id}/sync`);
export const createUpdate = (data) => post('/updates', data);

// export const createUserAvailability = (weekId, userId, slots) =>
//   post(`/users/${userId}/availability`, {
//     week_id: weekId,
//     slots,
//   })

export const createUserAvailability = (userId, payload) =>
  post(`/users/${userId}/availabilities`, payload);
export const batchCreateCommitments = (payload) => post('/slots', payload);
// export const createSession = token =>
//   post('/session', { auth_type: 'GOOGLE', access_token: token })
export const createProject = (data) => post('/projects', data);
// export const batchDestroyCommitments = ids => del(`/slots?ids=${ids.join(',')}`)
export const batchDestroyCommitments = (payload) => del(`/slots`, payload);

// DELETE
export const destroyUpdate = (id) => del(`/updates/${id}`);
export const destroyProject = (id) => del(`/projects/${id}`);

export const archiveProject = (id) => post(`/projects/${id}/archive`);
export const mergeIntoProject = (from, to) =>
  post(`/projects/${from}/merge_into/${to}`);

// export const refreshSession = bearerToken =>
//   post('/session', {
//     auth_type: 'REFRESH_TOKEN',
//     bearer_token: bearerToken,
//   })
export const changeNote = (data) => post('/slots', data);
