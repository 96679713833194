import React, { memo } from "react";

const Switch = memo(({ toggle, value, label }) => {
  return (
    <div className="switch ">
      <input
        className="switch__input"
        id="switchCheckbox"
        type="checkbox"
        checked={value}
        onChange={(e) => toggle()}
      />
      <label
        aria-hidden="true"
        className="switch__label"
        htmlFor="switchCheckbox"
      >
        {label ? label : "On"}
      </label>
      <div aria-hidden="true" className="switch__marker" />
    </div>
  );
});

export default Switch;
