import React, { memo, useState, useEffect } from 'react';
import { connect } from 'react-redux';

import SearchInput from './SearchInput';
import { closeModal, selectProject } from 'lib/reducers';

const mapStateToProps = ({ projects }) => ({ projects });

const SearchProject = (props) => {
  const [search, setSearch] = useState('');
  const [index, setIndex] = useState(-1);
  const [projects, setProjects] = useState([]);

  const { dispatch } = props;
  const { projects: all } = props;

  const onKeyUp = (event) => {
    event.preventDefault();
    if (event.keyCode === 38) {
      // arrow up
      if (index - 1 >= 0) setIndex(index - 1);
    } else if (event.keyCode === 40) {
      // arrow down
      if (index + 1 < projects.length) setIndex(index + 1);
    } else if (event.keyCode === 13) {
      // return
      if (index > -1 && projects[index]) {
        handleSelect(projects[index]);
      } else if (projects[0]) {
        handleSelect(projects[0]);
      }
    }
  };

  let init = false;
  useEffect(() => {
    if (!init) {
      window.document.addEventListener('keyup', onKeyUp);
      init = true;
    }

    return () => {
      window.document.removeEventListener('keyup', onKeyUp);
    };
  }, [index, projects]);

  const handleFilter = (search_str) => {
    setSearch(search_str);
    setIndex(-1);
  };

  useEffect(() => {
    const filtered = search
      ? all.filter((p) => p.name.toLowerCase().includes(search.toLowerCase()))
      : all;
    setProjects(filtered);
  }, [search]);

  const handleSelect = (selectedProject) => {
    dispatch(selectProject({ currentProject: selectedProject }));
    dispatch(closeModal());
  };

  return (
    <div className="search__list__wrap">
      <SearchInput autoFocus onSearch={handleFilter.bind(this)} />

      <div className="search__list">
        {search &&
          projects.map((p, i) => {
            return (
              <div
                key={p.id}
                className={`search_result  ${
                  i === index ? 'search_result--selected' : ''
                }`}
                onClick={() => handleSelect(p)}
              >
                {p.name}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(memo(SearchProject));
