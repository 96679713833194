import React, { Component } from 'react'
import RichTextEditor from 'react-rte'

export default class TextEditor extends Component {
  constructor(props) {
    super(props)

    const { initialValue } = this.props
    const format = 'markdown'

    this.state = {
      //value: RichTextEditor.createEmptyValue(),
      value: RichTextEditor.createValueFromString(initialValue, format),
    }
  }

  onChange = value => {
    this.setState({ value })
    if (this.props.onChange) {
      // Send the changes up to the parent component as an HTML string.
      // This is here to demonstrate using `.toString()` but in a real app it
      // would be better to avoid generating a string on each change.
      this.props.onChange(value.toString('markdown'))
    }
  }

  render() {
    const { value } = this.state
    return (
      <RichTextEditor
        className="rte"
        toolbarClassName="toolbar"
        editorClassName="editor"
        onChange={this.onChange}
        value={value}
      />
    )
  }
}
