import React, { useState } from 'react'
import Search from './Search'

const SearchInput = props => {
  const [value, setValue] = useState('')
  const handleChange = e => {
    const { value } = e.target
    setValue(value)
    props.onSearch(value)
  }
let autofocus ={}
  if(props && props.autoFocus){
    autofocus = {autoFocus:true}
  }

  return <Search value={value} onChange={e => handleChange(e)} {...autofocus}/>
}

export default SearchInput
