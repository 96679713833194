import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { labels } from 'labels';
import { getWeek, syncWeek } from 'lib/reducers/thunks';
import Icon from './Icon';
import { playSound } from 'lib/utils';
// import Switch from "./Switch";
import {
  toggleModal,
  toggleFullScreen,
  toggleSidebar,
  toggleDrawer,
  toggleHiddenUsers,
} from 'lib/reducers';

const mapStateToProps = ({
  fullScreen,
  sidebar,
  week,
  loading,
  showHiddenUsers,
  theme,
}) => ({
  fullScreen,
  sidebar,
  week,
  loading,
  showHiddenUsers,
  theme,
});

class Header extends PureComponent {
  handleFullscreen() {
    const div = document.getElementById('root');
    const { dispatch } = this.props;
    if (document.fullscreenElement) {
      document.exitFullscreen();
      dispatch(toggleFullScreen({ fullScreen: false }));
    } else {
      div.requestFullscreen();
      dispatch(toggleFullScreen({ fullScreen: true }));
    }
  }

  switchTheme() {
    const { theme: currentTheme } = this.props;
    const theme = currentTheme === 'classic' ? 'dark' : 'classic';
    this.props.dispatch({ type: 'THEME', payload: { theme } });
  }

  handleModal(modal = 1) {
    this.props.dispatch(toggleModal({ modal }));
  }

  handleSidebar() {
    this.props.dispatch(toggleSidebar());
  }

  handleDrawer() {
    this.props.dispatch(toggleDrawer());
  }

  handleChangeWeek(direction) {
    const { week, dispatch } = this.props;
    let change_week = moment();
    if (direction < 0) change_week = moment(week.start).subtract(1, 'week');
    if (direction > 0) change_week = moment(week.start).add(1, 'week');
    dispatch(getWeek(change_week));
    playSound('/sound/popcapdouble.wav');
  }

  handleSyncWeek(direction) {
    const { week, dispatch } = this.props;
    let change_week = moment(week.start);
    dispatch(syncWeek(change_week));
    playSound('/sound/risingspaceship.wav');
  }

  handleToggleHiddenUsers() {
    this.props.dispatch(toggleHiddenUsers());
  }

  formatDate() {
    const { week } = this.props;

    const currentMonday = moment().isoWeekday(1).startOf('day');
    const prevMonday = currentMonday.clone().subtract(1, 'week');
    const nextMonday = currentMonday.clone().add(1, 'week');

    const months = labels.months.split(',');
    const days_names = labels.days.split(',');

    const start = moment(week.start);
    const end = moment(week.end);

    const day_name_start = days_names[start.day() - 1];
    const day_name_end = days_names[end.day() - 1];
    const month = months[start.month()];
    const end_month = months[end.month()];

    let info = '';
    if (start.isSame(currentMonday)) {
      info = `(${labels.today})`;
    } else if (start.isSame(prevMonday)) {
      info = `(${labels.prev})`;
    } else if (start.isSame(nextMonday)) {
      info = `(${labels.next})`;
    }

    if (start.month() !== end.month()) {
      return `${day_name_start} ${start.date()} ${month} - ${day_name_end} ${end.date()} ${end_month} ${info}`;
    }
    return `${day_name_start} ${start.date()} - ${day_name_end} ${end.date()} ${month} ${info}`;
  }

  render() {
    const {
      sidebar,
      fullScreen,
      week,
      loading,
      showHiddenUsers,
      theme,
    } = this.props;
    if (!week) return <div>...</div>;
    return (
      <header style={{ width: '100vw', zIndex: 1 }}>
        <div className="">
          <div className="block--header">
            <div className="block__logo">
              <div className="logo">
                <figure className="logo__icon">
                  <Icon name="rum" className="logo__icon__l" />
                  <Icon name="mate" className="logo__icon__r" />
                </figure>
                <h1 className="logo__title">{labels.title}</h1>
              </div>
            </div>
            <nav className="block__aside">
              <ul className="buttons-list">
                <li className="buttons-list__item">
                  <button
                    className="btn btn--link"
                    onClick={() => this.handleChangeWeek(-1)}
                    title={labels.prev}
                  >
                    <Icon
                      name="left_arrow"
                      className="icon icon--sm"
                      alt={labels.prev}
                    />
                  </button>
                </li>
                <li className="buttons-list__item">
                  <button
                    className="btn btn--link"
                    onClick={() => this.handleChangeWeek(0)}
                    title={labels.today}
                  >
                    <Icon
                      name="disc"
                      className="icon icon--sm"
                      alt={labels.today}
                    />
                  </button>
                </li>
                <li className="buttons-list__item">
                  <button
                    className="btn btn--link"
                    onClick={() => this.handleChangeWeek(1)}
                    title={labels.next}
                  >
                    <Icon
                      name="right_arrow"
                      className="icon icon--sm"
                      alt={labels.next}
                    />
                  </button>
                </li>
              </ul>
            </nav>
            <div className="block__main">
              {loading && (
                <Icon
                  name="spinner"
                  alt={labels.reload}
                  className="icon icon--sm"
                  style={{
                    fill: 'var(--color-contrast-higher)',
                  }}
                />
              )}
              {loading === false && (
                <div className="block__title">{this.formatDate()}</div>
              )}
            </div>
            <nav className="block__right">
              <ul className="buttons-list">
                <li className="buttons-list__item">
                  <button
                    className="btn btn--link"
                    onClick={() => this.handleSyncWeek()}
                    disabled={loading}
                  >
                    <Icon
                      name="refresh"
                      className="icon icon--sm"
                      alt={labels.reload}
                    />
                  </button>
                </li>
                <li className="buttons-list__item">
                  <button
                    className="btn btn--link"
                    onClick={() => this.handleModal(6)}
                  >
                    <Icon
                      name="help"
                      className="icon icon--sm"
                      alt={labels.help}
                    />
                  </button>
                </li>
                <li className="buttons-list__item">
                  <button
                    className="btn btn--link"
                    onClick={() => this.handleToggleHiddenUsers()}
                  >
                    <Icon
                      name={showHiddenUsers ? 'eye' : 'eye_off'}
                      className="icon icon--sm"
                      alt={'show/hide hidden users'}
                    />
                  </button>
                </li>

                <li className="buttons-list__item">
                  <button
                    className="btn btn--link"
                    onClick={() => this.handleDrawer()}
                  >
                    <Icon name="note" className="icon icon--sm" />
                  </button>
                </li>
                <li className="buttons-list__item">
                  <button
                    className="btn btn--link"
                    onClick={() => this.handleModal(3)}
                  >
                    <Icon name="project" className="icon icon--sm" />
                  </button>
                </li>
                <li className="buttons-list__item">
                  <button
                    className="btn btn--link"
                    onClick={() => this.handleModal(4)}
                  >
                    <Icon name="user" className="icon icon--sm" />
                  </button>
                </li>
                <li className="buttons-list__item">
                  <button
                    className="btn btn--link"
                    onClick={() => this.handleFullscreen()}
                  >
                    {fullScreen ? (
                      <Icon name="contract" className="icon icon--sm" />
                    ) : (
                      <Icon name="expand" className="icon icon--sm" />
                    )}
                  </button>
                </li>
                <li className="buttons-list__item">
                  <button
                    className="btn btn--link"
                    onClick={() => this.handleSidebar()}
                  >
                    {sidebar ? (
                      <Icon name="menu" className="icon icon--sm" />
                    ) : (
                      <Icon name="menu" className="icon icon--sm" />
                    )}
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </header>
    );
  }
}

export default connect(mapStateToProps)(Header);
