import React from 'react';
import { connect } from 'react-redux';
import { closeModal } from 'lib/reducers';
import { ReactComponent as CloseModalIcon } from 'imgs/svg/md-close.svg';

const mapStateToProps = ({ modal }) => ({ modal });

class Modal extends React.PureComponent {
  handleClose() {
    this.props.dispatch(closeModal());
  }

  render() {
    const { modal, title, children, onActionClick, actionLabel } = this.props;
    return (
      <div className={`modal ${modal ? 'modal--is-visible' : ''}`}>
        <div
          className="modal__content modal__content--sm"
          role="alertdialog"
          aria-labelledby="modalTitle1"
          aria-describedby="modalDescription1"
        >
          {title && (
            <header className="modal__header">
              <h4 className="truncate">{title}</h4>
            </header>
          )}

          <div className="modal__body">
            <div className="text-component">{children}</div>
          </div>

          <footer className="modal__footer">
            {onActionClick && (
              <div className="flex flex--end flex--gap-sm">
                <button
                  className="btn btn--subtle"
                  onClick={() => this.handleClose()}
                >
                  Cancel
                </button>

                <button
                  className="btn btn--primary"
                  onClick={() => this.handleClose()}
                >
                  {actionLabel ? actionLabel : `ok`}
                </button>
              </div>
            )}
          </footer>
        </div>

        <button
          className="reset modal__close-btn"
          onClick={() => this.handleClose()}
        >
          <CloseModalIcon className="icon" alt="close modal" />
        </button>
      </div>
    );
  }
}

export default connect(mapStateToProps)(Modal);
