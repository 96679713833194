import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import User from './User';
import Allocation from './Allocation';
import { selectUser, sortUsersByProject } from 'lib/reducers';

const mapStateToProps = ({ users, currentUser, sortUser, showHiddenUsers }) => {
  return { users, currentUser, sortUser, showHiddenUsers };
};

class UserList extends Component {
  constructor(props) {
    super(props);
    this.refsCollection = [];
  }

  scrollToSelection(itemRef) {
    try {
      const { currentUser, dispatch } = this.props;
      const scrollTop = itemRef.offsetTop - this.domNode.offsetTop;
      // this.domNode.scrollTop = scrollTop
      document.querySelector('.main').scrollTop = scrollTop;
      document.getElementById(currentUser.id).className = 'block--highlighted';
      setTimeout(() => {
        document.getElementById(currentUser.id).className = 'block';
      }, 1000);
      const user = null;
      dispatch(selectUser({ user }));
    } catch (e) {
      console.error('error', e);
    }
  }

  componentWillReceiveProps(newProps) {
    if (
      newProps.currentUser !== null &&
      newProps.currentUser !== this.props.currentUser
    ) {
      const targetRef = this.refsCollection[newProps.currentUser.id];
      setTimeout(() => {
        this.scrollToSelection(targetRef);
      }, 250);
    }
  }
  render() {
    const { users, sortUser, showHiddenUsers } = this.props;
    const filtered = showHiddenUsers ? users : users.filter((u) => u.active);
    let lastUserId = -1;
    let userList = filtered;
    if (sortUser) {
      // sortedUsers = sortUsersByProject(users, sortUser);
      const scoredUsers = userList.filter((u) => u.score > 0);
      const last = _.last(scoredUsers);
      if (last) {
        lastUserId = last.id;
      }
    }

    return (
      <div className="main scrollbar" ref={(r) => (this.domNode = r)}>
        {userList.map((u) => {
          return (
            <div
              id={u.id}
              className={`block ${
                lastUserId === u.id ? 'block--separated' : ''
              }`}
              key={u.id}
              ref={(r) => {
                this.refsCollection[u.id] = r;
              }}
            >
              <div className="block__aside">
                <User user={u} />
              </div>

              <div className="block__main">
                <Allocation user={u} />
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default connect(mapStateToProps)(UserList);
