import React from "react";

// import { labels } from '../labels'
const shortcuts = {
  // m: labels.keyb_m,
  f: "Toggle FullScreen",
  h: "Shortcuts Help",
  l: "Switch user sort (if project selected)",
  p: "Project selector",
  j: "Project selector",
  u: "Serach and scroll to user",
  o: "Open Project (if project selected)",
  c: "Add note",
  n: "Add note",
  m: "Add to calendar",
  e: "Add to calendar",
  "<": "prev week",
  ">": "next week",
  esc: " close everything (fullscreen / modals / deselct project/ sidebar)",
};

const Help = () => {
  return (
    <div className="help">
      {Object.keys(shortcuts).map((k) => (
        <div key={k} className="help--item">
          <kbd>{k}</kbd> {shortcuts[k]}
        </div>
      ))}
    </div>
  );
};

export default Help;
