import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';

import {
  WORKING_DAYS,
  WORKING_HOURS,
  convertColor,
  playSound,
} from 'lib/utils';
import { showMenu, closeMenu } from 'lib/reducers';
import * as api from 'lib/api';

const mapStateToProps = ({
  currentProject,
  week,
  projects,
  currentNote,
  menu,
}) => ({
  currentProject,
  week,
  projects,
  currentNote,
  menu,
});

class Slot extends PureComponent {
  state = { hover: false, x: 0, y: 0, menu: false };

  handleOver(e) {
    e.preventDefault();
    if (this.state.menu || this.state.hover) return;
    this.setState({ hover: true, x: e.nativeEvent.offsetX });
    // console.log("x", e.nativeEvent.offsetX, "y", e.nativeEvent.offsetY);
    this.props.onCloseMenu();
  }

  handleOut() {
    this.setState({ hover: false, menu: false });
    //  this.props.onCloseMenu();
  }

  handleRightClick(e) {
    e.preventDefault();
    playSound('/sound/bubbles.wav');
    this.setState({
      menu: true,
      hover: false,
      x: e.nativeEvent.offsetX,
      y: e.nativeEvent.offsety,
    });

    // console.log(e.nativeEvent);

    if (this.props.onShowMenu) {
      const x = e.nativeEvent.pageX;
      const y = e.nativeEvent.pageY;
      const { note, hour, project_name, date } = this.props;
      const data = {
        note,
        hour,
        project_name,
        date,
        x,
        y,
      };
      this.props.onShowMenu(data);
    }
  }

  handleClick(e) {
    const {
      id,
      date,
      project_id,
      hour,
      currentProject,
      note,
      day,
    } = this.props;
    e.preventDefault();
    if (!currentProject && !project_id) return;
    const isNote = note ? true : false;
    const isBatch = e.altKey;
    this.props.onSlotClick(id, date, project_id, hour, isBatch, isNote, day);
    if (isBatch) {
      playSound('/sound/digitalfrog.wav');
    } else {
      playSound('/sound/clicks01.wav');
    }
  }

  render() {
    const {
      hover_color,
      color,
      note,
      hour,
      disabled,
      project_name,
      showName,
      isPast,
      isCurrent,
      menu,
      date,
    } = this.props;

    // const { hover, menu } = this.state // x, y,
    const { hover } = this.state;
    const background = hover && hover_color ? hover_color : convertColor(color);
    const style = hover_color || color ? { background } : {};
    const label =
      project_name && !disabled && hover ? (
        <div className="slot--label">
          {note ? <span style={{ color, marginRight: 5 }}>{note}</span> : null}
          <span>{project_name}</span>
        </div>
      ) : null;

    // const menuComp = menu ? <Menu user={{ name: 'ciao' }} /> : null

    return (
      <div
        className={`slot ${disabled ? 'slot--disabled' : ''} ${
          isPast ? 'slot--past' : ''
        } ${isCurrent ? 'slot--current' : ''} ${
          hour === 12 ? 'slot--spaced' : ''
        }`}
        style={style}
        onMouseEnter={(e) => this.handleOver(e)}
        onMouseLeave={() => this.handleOut()}
        onClick={(e) => !isPast && !disabled && this.handleClick(e)}
        onContextMenu={(e) =>
          project_name && !disabled && this.handleRightClick(e)
        }
      >
        {!disabled && !note && hover && hour}
        {note && <div className="slot--note" />}
        {label}
        <div className="slot__hour">{hour}</div>
        {project_name && showName && (
          <div className="slot--label--small">{project_name}</div>
        )}
      </div>
    );
  }
}

//

class Allocation extends PureComponent {
  onSlotClick(id, date, project_id, hour, isBatch, isNote, day) {
    const { user, currentProject, currentNote, readonly } = this.props;
    if (readonly) {
      return;
    }
    let hours = [hour];

    if (currentNote) {
      // let method = !isNote ? 'addNotes' : 'removeNotes'
      const payload = {
        slot: {
          id,
          hours,
          project_id,
          user_id: user.id,
          note: isNote ? null : currentNote,
          when_date: date,
        },
      };
      api.changeNote(payload);
      return;
    }

    if (isBatch) {
      const available_hours = user.availabilities[day].map((i) => i.hour);
      hours = available_hours.filter((i) => i >= hour);
    }

    const add = currentProject && project_id !== currentProject.id;
    //move to thunk
    if (add) {
      const payload = {
        slot: {
          hours,
          user_id: user.id,
          project_id: currentProject.id,
          when_date: date,
        },
      };
      api.batchCreateCommitments(payload);
    } else if (user.commitments[date]) {
      const ids = hours
        .map((hour) => {
          const commitment = user.commitments[date].find(
            (c) => c.hour === hour
          );
          if (commitment) return commitment.id;
          return null;
        })
        .filter((id) => id != null);
      if (ids.length > 0) {
        const payload = { slot: { ids: ids } };
        api.batchDestroyCommitments(payload);
      }
    }
  }

  renderDay(day, date, index) {
    const { user, currentProject, projects, currentNote } = this.props;
    const commitments = user.commitments[date];

    let projects_map = [];
    if (commitments) {
      const sorted = _.sortBy(commitments, 'hour');
      projects_map = sorted.map((c, i) => {
        const { project_id: id } = c;
        const { hour } = c;
        return { hour, id, i };
      });
    }
    const filtered = [];
    let previous = null;
    for (let i = 0; i < projects_map.length; i++) {
      const item = Object.assign({}, projects_map[i]);
      if (item.id !== previous) {
        filtered.push(item);
      }
      previous = item.id;
    }

    const now = moment();
    const diff = now.diff(moment(date));
    const elapsed = moment.duration(diff).as('days');
    const isPast = elapsed >= 1;

    return (
      <div className="day" key={`${user.id}-${date}`}>
        {WORKING_HOURS.map((h, i) => {
          const commitment = commitments
            ? commitments.find((c) => c.hour === h)
            : null;

          let project = null;
          if (commitment) {
            project = projects.find((p) => p.id === commitment.project_id);
          }

          const dayIndex = WORKING_DAYS.findIndex((d) => d === day) + 1;
          let slotAvailable = true;
          const dayAvability = user.availabilities[dayIndex];
          if (dayAvability) {
            slotAvailable = dayAvability.findIndex((a) => a.hour === h);
          }
          const isCurrent = elapsed >= 0 && elapsed < 1 && now.hour() === h;
          const slot = {
            currentProject,
            currentNote,
            user_id: user.id,
            date,
            isPast,
            isCurrent,
            id: commitment ? commitment.id : null,
            disabled: slotAvailable < 0,
            hover_color: currentProject ? currentProject.color : null,
            project_id: project ? project.id : null,
            project_name: project ? project.name : null,
            color: project ? project.color : null,
            note: commitment ? commitment.note : null,
            showName: filtered && filtered.find((f) => f.hour === h) != null,
          };
          const key = `${user.id}-${date}-${h}`;
          return (
            <Slot
              key={key}
              hour={h}
              day={index + 1}
              onSlotClick={this.onSlotClick.bind(this)}
              onShowMenu={this.handleShowMenu.bind(this)}
              onCloseMenu={this.handleCloseMenu.bind(this)}
              {...slot}
            />
          );
        })}
      </div>
    );
  }

  handleShowMenu(data) {
    // console.log('DATA', data);
    this.props.dispatch(showMenu(data));
  }
  handleCloseMenu() {
    this.props.dispatch(closeMenu());
  }

  render() {
    const { week, menu } = this.props;

    // INSTEAD OF RENDER A WEEK USE A RANGE TO DISPLAY DAYS, SKIPPING saturday and sunday
    // var a = moment(week.start);
    // var b = moment(week.end);
    // const days_range = a.diff(b, "days"); // =1
    // console.log("days_count", days_count);
    // const dataToDayMap = mapDaysToCommitmens(user.commitments);

    return (
      <div className="week">
        {WORKING_DAYS.map((day, i) => {
          // const date = dataToDayMap[i + 1];
          const date = moment(week.start).add(i, 'days').format('YYYY-MM-DD');
          return this.renderDay(day, date, i);
        })}
      </div>
    );
  }
}

export default connect(mapStateToProps)(Allocation);
