import React, { Component } from "react";
import { ReactComponent as SearchIcon } from "../imgs/icons/search.svg";

const Search = (props) => {
  return (
    <div className="search-input search-input--icon-left">
      <input
        className="form-control"
        type="search"
        placeholder="Search..."
        {...props}
      />
      <button className="search-input__btn">
        <SearchIcon className="icon" alt={"search"} />
      </button>
    </div>
  );
};

export default Search;
