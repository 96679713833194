import React, { PureComponent } from 'react';
import RichTextEditor from 'react-rte';
import { marked } from 'marked';
import moment from 'moment';
import * as api from 'lib/api';
import UserAvatar from './UserAvatar';

const fmt = 'DD-MM-YY  HH:mm';

export default class TextEditor extends PureComponent {
  constructor(props) {
    super(props);
    const { update } = this.props;
    const initialValue = update.description;
    const format = 'markdown';

    this.state = {
      //value: RichTextEditor.createEmptyValue(),
      value: RichTextEditor.createValueFromString(initialValue, format),
      edit: false,
      update,
    };
  }

  onChange = (value) => {
    this.setState({ value });
  };

  async onSave() {
    this.onEdit(false);
    const { id } = this.props.update;
    const { user_id, project_id } = this.props;
    const { value } = this.state;
    const description = value.toString('markdown');
    const payload = {
      user_id,
      project_id,
      description,
    };
    await api.updateUpdate(id, payload);
    this.props.onUpdate();
  }

  onDelete() {
    api.destroyUpdate(this.props.update.id);
  }

  onEdit(edit) {
    this.setState({ edit });
  }

  render() {
    const { edit, update, value } = this.state;
    const { user_id, author } = this.props;
    const canEdit = author.id === user_id;
    return (
      <div className="timeline-content">
        <div className="timeline-event">
          <div className="timeline-date">
            {`${moment(update.created_at).format(fmt)}`}
          </div>
          <div className="timeline-user">
            <div>
              <UserAvatar user={author} />
            </div>
            <div>{author.fullName}</div>
          </div>
        </div>

        {edit && (
          <div className="timeline-detail">
            <RichTextEditor
              className="rte"
              toolbarClassName="toolbar"
              editorClassName="editor"
              onChange={this.onChange}
              value={value}
            />
            <div className="timeline-buttons">
              <button
                className="btn btn--subtle btn--sm"
                onClick={() => this.onEdit(false)}
              >
                cancel
              </button>
              <button
                className="btn btn--subtle btn--sm"
                onClick={() => this.onSave()}
              >
                save
              </button>
            </div>
          </div>
        )}

        {!edit && (
          <div className="timeline-detail">
            <div
              dangerouslySetInnerHTML={{
                __html: marked.parse(update.description),
              }}
            />
            {canEdit && (
              <div className="timeline-buttons">
                <button
                  className="btn btn--subtle btn--sm"
                  onClick={() => this.onEdit(true)}
                >
                  edit
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}
