import React from 'react';
import { connect } from 'react-redux';
import { getMeetingUrl } from 'lib/utils';
import moment from 'moment';

const Menu = ({ menu, onClose }) => {
  const { note, hour, project_name: project, date } = menu;
  const startDate = moment(`${date} ${hour}`).format();
  const data = {
    note,
    project,
    startDate,
  };

  return (
    <div className="menu-wrapper" data-theme="dark">
      <menu
        className="menu menu--is-visible"
        style={{ border: '2px solid white' }}
      >
        <li className="menu__item">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={getMeetingUrl(data)}
            className="menu__item"
          >
            ADD TO CALENDAR
          </a>
        </li>

        <li className="menu__separator" />
        <li>
          <a href="#0" onClick={onClose} className="menu__item">
            CLOSE
          </a>
        </li>
      </menu>
    </div>
  );
};

const mapStateToProps = ({ menu }) => {
  return { menu };
};
export default connect(mapStateToProps)(Menu);
