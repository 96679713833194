import React, { memo, useState, useEffect } from 'react';
import { connect } from 'react-redux';

import SearchInput from './SearchInput';
import { closeModal, selectUser } from 'lib/reducers';

const mapStateToProps = ({ users }) => ({ users });

const SearchUser = (props) => {
  const [search, setSearch] = useState('');
  const [index, setIndex] = useState(-1);
  const [users, setUsers] = useState([]);

  const { dispatch } = props;
  const { users: all } = props;

  const onKeyUp = (event) => {
    event.preventDefault();

    if (event.keyCode === 38) {
      // arrow up
      if (index - 1 >= 0) setIndex(index - 1);
    } else if (event.keyCode === 40) {
      // arrow down
      if (index + 1 < users.length) setIndex(index + 1);
    } else if (event.keyCode === 13) {
      // return
      if (index > -1 && users[index]) {
        handleSelect(users[index]);
      } else if (users[0]) {
        handleSelect(users[0]);
      }
    }
  };

  let init = false;
  useEffect(() => {
    if (!init) {
      console.log('Mount search User', index);
      window.document.addEventListener('keyup', onKeyUp);
      init = true;
    }
    return () => {
      window.document.removeEventListener('keyup', onKeyUp);
      console.log('Unmount search user');
    };
  }, [index, users]);

  const handleFilter = (search_str) => {
    setSearch(search_str);
    setIndex(-1);
  };

  useEffect(() => {
    const filtered = search
      ? all.filter((u) =>
          u.fullName.toLowerCase().includes(search.toLowerCase())
        )
      : all;
    setUsers(filtered);
  }, [search]);

  const handleSelect = (user) => {
    console.log('user', user);
    dispatch(selectUser({ user }));
    dispatch(closeModal());
  };

  return (
    <div className="search__list__wrap">
      <SearchInput autoFocus onSearch={handleFilter.bind(this)} />

      <div className="search__list">
        {search &&
          users.map((u, i) => {
            return (
              <div
                key={u.id}
                className={`search_result  ${
                  i === index ? 'search_result--selected' : ''
                }`}
                onClick={() => handleSelect(u)}
              >
                {u.fullName}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(memo(SearchUser));
