import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getProjectDetails } from 'lib/reducers/thunks';
import { resetProjectUpdates, closeModal } from 'lib/reducers';
import Update from './Update';
import TextEditor from './TextEditor';
import Emoji from './Emoji';
import * as api from 'lib/api';
import { log } from 'lib/utils';

const mapStateToProps = ({
  userId,
  projectUpdates,
  modalContent,
  users,
  all_users,
}) => ({
  projectUpdates,
  modalContent,
  userId,
  users,
  all_users,
});

const ProjectDeatil = (props) => {
  const [md, setMd] = useState(null);
  const [updates, setUpdates] = useState([]);
  const {
    dispatch,
    projectUpdates,
    modalContent,
    userId,
    users,
    all_users,
  } = props;
  const { id } = modalContent;
  useEffect(() => {
    log('get project');
    dispatch(getProjectDetails(id));
    return () => {
      dispatch(resetProjectUpdates());
    };
  }, []);

  useEffect(() => {
    log('received updates');
    if (projectUpdates) {
      const { updates: lastUpdates } = projectUpdates;
      setUpdates(lastUpdates);
    }
  }, [projectUpdates]);

  const setMarkdown = (markdown) => {
    setMd(markdown);
  };

  const onSaveUpdate = async () => {
    if (!md || md.length === 0) return;

    const payload = {
      user_id: userId,
      project_id: id,
      description: md,
    };

    log('CREATE UPDATE', payload);
    await api.createUpdate(payload);
    dispatch(getProjectDetails(id));
    setMd(null);
    dispatch(closeModal());
  };

  const onUpdate = () => {
    dispatch(getProjectDetails(id));
  };

  const renderForm = () => {
    return (
      <div className="flex--column flex--end">
        <TextEditor initialValue={''} onChange={setMarkdown} />
        <div className="flex-item--end">
          <button
            disabled={md === null}
            className={`btn btn--primary btn--sm ${
              md && md.length > 0 ? '' : 'btn--disabled'
            }`}
            onClick={() => onSaveUpdate()}
          >
            save
          </button>
        </div>
      </div>
    );
  };

  if (!projectUpdates) return <div>...</div>;

  return (
    <div>
      <h3>Cosa c'è di nuovo?</h3>
      {renderForm()}
      {!updates ||
        (updates.length === 0 && (
          <div>
            Nessun update <Emoji label="ops" symbol="💩" />
          </div>
        ))}
      {updates && updates.length > 0 && (
        <div>
          <h3>Last Updates</h3>
          {updates.map((u, i) => {
            log('U', u);
            const user = all_users.find((i) => i.id === parseInt(u.user_id));
            log('user', user);
            const author = {
              id: user.id,
              fullName: '' + user.fullName,
              gravatar_hash: user.gravatar_hash,
              profile_image_url: user.profile_image_url,
            };
            log('AUTHOR', author);

            return (
              <div key={i}>
                <Update
                  update={u}
                  author={author}
                  onUpdate={onUpdate}
                  user_id={userId}
                  project_id={id}
                />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default connect(mapStateToProps)(React.memo(ProjectDeatil));
