import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { toggleDrawer, selectNote, addNote, resetNote } from 'lib/reducers';

const mapStateToProps = ({ notes, currentNote, drawer }) => ({
  notes,
  drawer,
  currentNote,
});

const Notes = (props) => {
  const { dispatch, drawer } = props;
  const [selectedNote, setSelectedNote] = useState('');
  const inputRef = useRef(null);

  useEffect(() => {
    if (drawer && inputRef && inputRef.current) {
      console.log('set focus');
      inputRef.current.focus();
    }
  }, [drawer, inputRef]);

  function handleClose() {
    console.log('RESET AND CLOSE');
    dispatch(resetNote());
    dispatch(toggleDrawer());
  }

  function handleClick(note) {
    const { currentNote } = props;
    note = note && currentNote === note ? null : note;
    dispatch(selectNote({ currentNote: note }));
  }

  function handleChange(e) {
    setSelectedNote(e.target.value);
  }

  function handleAddNote(e) {
    e.preventDefault();
    if (selectedNote) {
      dispatch(addNote({ note: selectedNote }));
    }
    setSelectedNote('');
  }

  const { notes, currentNote } = props;
  return (
    <div>
      {notes.map((note) => (
        <div
          className={`note ${note === currentNote ? 'note--selected' : ''}`}
          key={note}
          onClick={() => handleClick(note)}
        >
          {note}
        </div>
      ))}
      <div>
        <div className="text-component text--center margin-top--md margin-bottom--sm">
          Add note
        </div>
        <form onSubmit={(e) => handleAddNote(e)}>
          <input
            className="form-control input"
            type="text"
            value={selectedNote}
            onChange={(e) => handleChange(e)}
            ref={inputRef}
          />
          <button className="orm-control  btn btn--primary" type="submit">
            Add
          </button>
        </form>
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(Notes);
