import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
// import { gravatar } from "../utils";
import UserAvatar from './UserAvatar';
import UserInfo from './UserInfo';
import { toggleModal } from 'lib/reducers';

const mapStateToProps = ({ modal }) => ({
  modal,
});

class User extends PureComponent {
  handleClick() {
    const { dispatch, user, readonly } = this.props;
    if (readonly) {
      // console.log('readonly');
      return;
    }
    const { fullName, id } = user;
    dispatch(toggleModal({ modal: 1, content: { fullName, id } }));
  }
  render() {
    const { user } = this.props;
    return (
      <div className="user" onClick={() => this.handleClick()}>
        <UserAvatar user={user} />

        <div className="user__main">
          <div className="user__name">{`${user.last_name}`}</div>
          <UserInfo user={user} />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(User);
