import Pusher from 'pusher-js';
import {
  addCommitments,
  removeCommitments,
  updateUser,
  // createProject,
  updateProject,
  // archiveProject,
} from 'lib/reducers';

// import Notifier from 'react-desktop-notification'
// const notify = (title = 'ciao', text = '^^', link = '', img = null) => {
//   Notifier.focus(title, text, link, null)
// }

const EVENTS = {
  TEST: 'test',
  ARCHIVE_PROJECT: 'archive_project',
  CREATE_PROJECT: 'create_project',
  DESTROY_PROJECT: 'destroy_project',
  UPDATE_PROJECT: 'update_project',
  ADD_COMMITMENTS: 'add_slots',
  REMOVE_COMMITMENTS: 'remove_slots',
  CHANGE_AVAILABILITIES: 'change_availabilities',
  CREATE_UPDATE: 'create_update',
  DESTROY_UPDATE: 'destroy_update',
  UPDATE_UPDATE: 'update_update',
  USER_STATUS_CHANGED: 'user_status_changed',
};

function parseCommitments(slots) {
  return slots.data.map((slot) => {
    let c = slot.attributes;
    c.date = c.when_date;
    c.user_id = parseInt(slot.relationships.user.data.id);
    c.project_id = parseInt(slot.relationships.project.data.id);
    c.id = parseInt(c.id);
    delete c.when_date;
    delete c.updated_at;
    delete c.created_at;
    return c;
  });
}

const PUSHER_APP = process.env.REACT_APP_PUSHER_KEY;
const PUSHER_CHANNEL = process.env.REACT_APP_PUSHER_ENV;

const initSocket = (dispatch) => {
  const pusher = new Pusher(PUSHER_APP, {
    cluster: 'eu',
    forceTLS: true,
  });
  const socket = pusher.subscribe(PUSHER_CHANNEL);

  socket.bind(EVENTS.ADD_COMMITMENTS, (data) => {
    const slots = data.commitments;
    if (slots) {
      const commitments = parseCommitments(slots);
      dispatch(addCommitments({ commitments }));
    }
  });
  socket.bind(EVENTS.REMOVE_COMMITMENTS, (data) => {
    const slots = data.commitments;
    if (slots) {
      const commitments = parseCommitments(slots);
      dispatch(removeCommitments({ commitments }));
    }
  });

  socket.bind(EVENTS.USER_STATUS_CHANGED, (data) => {
    console.log('USER_STATUS_CHANGED', JSON.stringify(data, null, 2));
    dispatch(updateUser(data));
  });

  // console.log('INIT PUSHER')
  // socket.bind(EVENTS.ARCHIVE_PROJECT, data => {
  //   // console.log('archive_project', data)
  //   dispatch(archiveProject(data))
  // })
  // socket.bind(EVENTS.CREATE_PROJECT, data => {
  //   // console.log(' create_project', data)
  //   dispatch(createProject(data))
  // })
  socket.bind(EVENTS.UPDATE_PROJECT, (data) => {
    console.log('UPDATE_PROJECT', JSON.stringify(data, null, 2));
    dispatch(updateProject(data));
  });

  // socket.bind(EVENTS.DESTROY_PROJECT, data => {
  //   // console.log('destroy_project', data)
  // })
  // socket.bind(EVENTS.CHANGE_AVAILABILITIES, (data) => {
  //   console.log("change_availabilities", data);
  // });
  // socket.bind(EVENTS.CREATE_UPDATE, data => {
  //   // console.log('create_update', data)
  // })
  // socket.bind(EVENTS.DESTROY_UPDATE, data => {
  //   // console.log('destroy_update', data)
  // })
  // socket.bind(EVENTS.UPDATE_UPDATE, data => {
  //   // console.log('update_update', data)
  // })
  // socket.bind(EVENTS.TEST, data => {
  //   // console.log('test', data)
  // })

  return socket;
};
export default initSocket;
