import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import UserList from './UserList';
import ProjectList from './ProjectList';
import Header from './Header';
import Modal from './Modal';
import Drawer from './Drawer';
import Notes from './Notes';
import UserAvabilities from './UserAvabilities';
import ProjectDetail from './ProjectDetail';
import Menu from './Menu';
import Help from './Help';

import SearchProject from './SearchProject';
import SearchUser from './SearchUser';

import { syncWeek } from 'lib/reducers/thunks';
import { getMeetingUrl } from 'lib/utils';
import {
  toggleSidebar,
  toggleDrawer,
  toggleModal,
  closeModal,
  resetProject,
  toggleFullScreen,
  changeUsersOrder,
  resetNote,
  closeMenu,
} from 'lib/reducers';

class Home extends PureComponent {
  componentWillMount() {
    // this.setCustomTheme(this.props.theme);
    this.props.dispatch(syncWeek());
  }

  componentDidMount() {
    window.document.addEventListener('keyup', (e) => this.onKeyUp(e));
  }

  componentWillUnmount() {
    window.document.removeEventListener('keyup', (e) => this.onKeyUp(e));
  }

  handleCloseNotes() {
    this.props.dispatch(resetNote());
  }

  onKeyUp(event) {
    const { dispatch, week, drawer, modal, currentProject } = this.props;

    if (drawer || modal) {
      switch (event.keyCode) {
        case 27: // escape
          if (modal) dispatch(closeModal());
          else if (drawer) {
            dispatch(toggleDrawer());
            this.handleCloseNotes();
          }
          return;
        default:
          return;
      }
    }

    if (
      event.target.tagName === 'INPUT' ||
      event.target.tagName === 'TEXTAREA'
    ) {
      return;
    }

    switch (event.keyCode) {
      case 72: // ha
        // help
        dispatch(toggleModal());
        return;
      case 76: // l
        // sort by selected project
        dispatch(changeUsersOrder());
        return;
      case 79: // o
        // project detail
        if (!currentProject) return;
        const { id, name } = currentProject;
        dispatch(toggleModal({ modal: 2, content: { name, id } }));
        return;
      case 74: // j
      case 80: // p
        // quick pick project
        dispatch(toggleModal({ modal: 3, content: null }));
        return;
      case 85: // u
        // goto user
        dispatch(toggleModal({ modal: 4, content: null }));
        return;
      case 78: // n
      case 67: // c
        // add note
        dispatch(toggleDrawer());
        return;
      case 70: // f
        // fullscreen
        this.handleFullscreen();
        return;
      case 27: // escape
        // reset rpoject
        if (document.fullscreenElement) {
          this.props.dispatch(toggleFullScreen({ fullScreen: false }));
        } else if (modal) dispatch(toggleModal());
        else if (drawer) dispatch(toggleDrawer());
        else if (currentProject) dispatch(resetProject());
        else dispatch(toggleSidebar());
        return;
      case 37: // left
        const prevWeek = moment(week.start).subtract(1, 'week');
        dispatch(syncWeek(prevWeek));
        return;
      case 39: // right
        const nextWeek = moment(week.start).add(1, 'week');
        dispatch(syncWeek(nextWeek));
        return;
      case 77: // m
      case 69: // e
        // add note
        dispatch(toggleModal({ modal: 5, content: 'Create Event' }));
        return;
      default:
        // console.log("KEYCODE", event.keyCode);
        return;
    }
  }

  // componentWillReceiveProps(next) {
  // if (next.theme !== this.props.theme) {
  //   this.setCustomTheme(next.theme);
  // }
  // }

  setCustomTheme(theme) {
    // const theme_selector = document.querySelector(`[data-theme="${theme}"]`);
    const theme_selector = document.querySelector('.wrapper');
    const color = '#eee';

    if (theme_selector != null) {
      if (theme !== 'dark') {
        theme_selector.style.setProperty('--color-sidebar', color);
      } else {
        theme_selector.style.setProperty('--color-sidebar', color);
      }
    }
  }

  renderModal() {
    const { modal, modalContent, currentProject, currentNote } = this.props;
    const modalProps = {
      title: null,
      children: null,
      onActionClick: null,
      actionLabel: null,
    };

    if (modal === 1) {
      modalProps.title = `${modalContent.fullName}`;
      modalProps.children = <UserAvabilities />;
      // modalProps.actionLabel = 'Salva'
      // modalProps.onActionClick = data => {
      //   dispatch(toggleModal())
      // }
    } else if (modal === 2) {
      modalProps.title = `${modalContent.name}`;
      modalProps.children = <ProjectDetail />;
    } else if (modal === 3) {
      modalProps.title = `select project`;
      modalProps.children = (
        <div>
          <SearchProject />
        </div>
      );
    } else if (modal === 4) {
      modalProps.title = `select user`;
      modalProps.children = (
        <div>
          <SearchUser />
        </div>
      );
    } else if (modal === 5) {
      modalProps.title = `Create Event`;

      const data = {
        note: currentNote ? currentNote : '',
        project: currentProject ? currentProject.name : '',
        startDate: moment(),
      };
      modalProps.children = (
        <div>
          {data.project && <p>{`${data.project} ${data.note}`}</p>}
          <a
            className="btn btn--primary btn--md"
            href={getMeetingUrl(data)}
            target="_blank"
          >
            Add Calendar
          </a>
        </div>
      );
    } else {
      modalProps.title = `Keyboard shortcuts`;
      modalProps.children = <Help />;
    }

    return <Modal className="" {...modalProps} />;
  }

  handleCloseMenu() {
    this.props.dispatch(closeMenu());
  }
  render() {
    const { sidebar, theme, modal, drawer, menu } = this.props;

    return (
      <div className="wrapper" data-theme={theme}>
        <Header />
        {this.props.loading && <div>...</div>}
        {this.props.error ? (
          <div
            className="content"
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <h1>Oh no! 🙃 </h1>
            <h3>impossibile raggiungere chimera.</h3>
            <div>prova a fare logout e poi login da chimera.</div>
          </div>
        ) : (
          <div className="content">
            <div className="main">
              <UserList />
            </div>
            <div
              // data-theme={sidebarTheme}
              className={`sidebar ${
                sidebar ? 'sidebar--is--visible' : 'sidebar--is--hidden'
              }`}
            >
              <ProjectList />
            </div>
          </div>
        )}
        {drawer && (
          <Drawer
            title="note"
            side="right"
            onClose={() => this.handleCloseNotes()}
          >
            <Notes />
          </Drawer>
        )}
        {modal && this.renderModal()}
        {menu && (
          <div
            style={{
              position: 'absolute',
              left: menu.x,
              top: menu.y,
              zIndex: 90000,
              minWidth: 180,
              minheight: 200,
            }}
          >
            <Menu data={menu} onClose={() => this.handleCloseMenu()} />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({
  theme,
  sidebar,
  week,
  fullScreen,
  drawer,
  modal,
  modalContent,
  currentProject,
  menu,
}) => ({
  theme,
  sidebar,
  week,
  fullScreen,
  drawer,
  modal,
  modalContent,
  currentProject,
  menu,
});
export default connect(mapStateToProps)(Home);
