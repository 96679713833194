import React, { memo } from 'react';
import { connect } from 'react-redux';
import { labels } from 'labels';

const mapStateToProps = ({ showHiddenUsers }) => ({ showHiddenUsers });

const UserInfo = memo((props) => {
  const { user: u, showHiddenUsers } = props;

  return (
    <div
      className="user__text"
      onContextMenu={(e) => {
        alert(u.fullName);
      }}
    >
      {`${u.totalCommitments}/${u.available} `}
      {showHiddenUsers && <p>{u.active ? 'Active' : 'Disabled'}</p>}
    </div>
  );
});

export default connect(mapStateToProps)(UserInfo);
