import produce from 'immer';
import _ from 'lodash';
import { getGravatarHash } from 'lib/utils';

//ACTION CONSTANTS
export const FULLSCREEN = 'FULLSCREEN';
export const SIDEBAR = 'SIDEBAR';
export const THEME = 'THEME';
export const DRAWER = 'DRAWER';
export const MODAL = 'MODAL';
export const MODAL_CLOSE = 'MODAL_CLOSE';

export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_PROJECT = 'UPDATE_PROJECT';

export const CREATE_PROJECT = 'CREATE_PROJECT';
export const ARCHIVE_PROJECT = 'ARCHIVE_PROJECT';
export const CURRENT_PROJECT = 'CURRENT_PROJECT';
export const SET_WEEK_DATA = 'SET_WEEK_DATA';

export const REMOVE_COMMITMENTS = 'REMOVE_COMMITMENTS';
export const ADD_COMMITMENTS = 'ADD_COMMITMENTS';

export const LOADING = 'LOADING';
export const DONE = 'DONE';

export const CURRENT_USER = 'CURRENT_USER';
export const SORT_USERS = 'SORT_USERS';
export const SORT_PROJECTS = 'SORT_PROJECTS';

export const PROJECT_UPDATES = 'PROJECT_UPDATES';
export const REMOVE_PROJECT_UPDATES = 'REMOVE_PROJECT_UPDATES';

export const CURRENT_NOTE = 'CURRENT_NOTE';
export const RESET_NOTE = 'RESET_NOTE';
export const ADD_NOTE = 'ADD_NOTE';

export const SET_USER_ID = 'SET_USER_ID';

export const SHOW_MENU = 'SHOW_MENU';
export const CLOSE_MENU = 'CLOSE_MENU';

export const TOGGLE_HIDDEN_USERS = 'TOGGLE_HIDDEN_USERS';

// ACTION FUNC
export const toggleFullScreen = (payload) => ({ type: FULLSCREEN, payload });
export const toggleSidebar = () => ({ type: SIDEBAR });
export const switchTheme = (payload) => ({ type: THEME, payload });
export const toggleDrawer = () => ({ type: DRAWER });
export const toggleModal = (payload) => ({ type: MODAL, payload });
export const closeModal = (payload) => ({ type: MODAL_CLOSE });

export const createProject = (payload) => ({ type: CREATE_PROJECT, payload });
export const archiveProject = (payload) => ({ type: ARCHIVE_PROJECT, payload });
export const updateProject = (payload) => ({ type: UPDATE_PROJECT, payload });
export const selectProject = (payload) => ({ type: CURRENT_PROJECT, payload });
export const resetProject = () => {
  const payload = { currentProject: null };
  return { type: 'CURRENT_PROJECT', payload };
};
export const setProjectUpdates = (payload) => ({
  type: PROJECT_UPDATES,
  payload,
});
export const resetProjectUpdates = () => ({ type: REMOVE_PROJECT_UPDATES });

export const changeUsersOrder = () => ({ type: SORT_USERS });
export const updateUser = (payload) => ({ type: UPDATE_USER, payload });
export const addCommitments = (payload) => ({ type: ADD_COMMITMENTS, payload });
export const removeCommitments = (payload) => ({
  type: REMOVE_COMMITMENTS,
  payload,
});
export const selectUser = (payload) => ({ type: CURRENT_USER, payload });

export const loading = () => ({ type: LOADING });
export const setWeekData = (payload) => ({ type: SET_WEEK_DATA, payload });
export const done = () => ({ type: DONE });

export const selectNote = (payload) => ({ type: CURRENT_NOTE, payload });
export const resetNote = () => ({ type: RESET_NOTE });
export const addNote = (payload) => ({ type: ADD_NOTE, payload });

export const setUserId = (payload) => ({ type: SET_USER_ID, payload });

export const showMenu = (payload) => ({ type: SHOW_MENU, payload });
export const closeMenu = () => ({ type: CLOSE_MENU });

export const toggleHiddenUsers = () => ({ type: TOGGLE_HIDDEN_USERS });

// REDUCER
const initialState = {
  userId: null,
  // data
  week: null,
  users: [],
  all_users: [],
  projects: [],
  currentProject: null,
  projectUpdates: null,

  notes: [],
  // settings
  theme: 'dark',
  // overlays
  fullScreen: false,
  sidebar: true,
  drawer: false,
  modal: false,
  modalContent: null,
  // feedbacks
  loading: true,
  notifications: [],

  currentUser: null,
  sortUser: 0,

  menu: null,
  showHiddenUsers: false,
};

const rootReducer = produce((draft, action) => {
  // console.log('ACTION = ', action, action.payload)
  switch (action.type) {
    case TOGGLE_HIDDEN_USERS:
      draft.showHiddenUsers = !draft.showHiddenUsers;
      return;
    case SET_USER_ID:
      const { user_id } = action.payload;
      draft.userId = user_id;
      return;
    case CURRENT_USER:
      const { user } = action.payload;
      draft.currentUser = user;
      return;
    case UPDATE_USER:
      const { payload: user_update } = action;
      const user_index = draft.users.findIndex((u) => u.id === user_update.id);
      draft.users[user_index] = _.extend(
        _.cloneDeep(draft.users[user_index]),
        _.cloneDeep(user_update)
      );
      return;
    case SORT_USERS:
      const sort =
        draft.currentProject && draft.sortUser !== draft.currentProject.id
          ? draft.currentProject.id
          : 0;
      if (sort === draft.sortUser) return;
      draft.sortUser = sort;
      draft.users = sortUsers(draft, sort);
      return;
    case ADD_COMMITMENTS:
      //CACHE count on fact that commitments arrays are usually related to same user or project
      action.payload.commitments.reduce(
        (cache, c) => {
          const { user_id, project_id, date, hour } = c;

          //GET USER INDEX
          const user_index =
            user_id === cache.user_id
              ? cache.user_index
              : draft.users.findIndex((u) => u.id === user_id);

          //GET PROJECT INDEX
          const project_index =
            project_id === cache.project_id
              ? cache.project_index
              : draft.projects.findIndex((p) => p.id === project_id);

          //INCREMET PROJECT COMMITMENT COUNT
          draft.projects[project_index].totalCommitments += 1;

          //CREATE AN EMPTY "DAY" FOR THAT DATE IF NOT EXISTS
          if (!draft.users[user_index].commitments[date]) {
            draft.users[user_index].commitments[date] = [];
          }

          //CHECK OVERLAPPING SLOTS
          const owerwrite = draft.users[user_index].commitments[date].findIndex(
            (i) => i.hour === hour
          );
          if (owerwrite > -1) {
            //REMOVE COMMITMENT FROM COUNT ON OVEWRITTEN PROJECT
            const old_project_id =
              draft.users[user_index].commitments[date][owerwrite].project_id;

            const old_project_index = draft.projects.findIndex(
              (p) => p.id === old_project_id
            );
            draft.projects[old_project_index].totalCommitments -= 1;
          } else {
            //INCREMET USER COMMITMENT COUNT
            draft.users[user_index].totalCommitments += 1;
          }

          //UPDATE USER
          draft.users[user_index].commitments[date].push(c);

          if (owerwrite > -1) {
            //REMOVE OVEWRITTEN COMMITMENTS
            draft.users[user_index].commitments[date].splice(owerwrite, 1);
          }
          draft.users[user_index].lastMod = Date.now();

          //RETURN CAHCE
          return {
            user_id,
            user_index,
            project_id,
            project_index,
          };
        },
        {
          user_id: null,
          user_index: null,
          project_id: null,
          project_index: null,
        }
      );

      draft.modTs = Date.now();
      return;
    case REMOVE_COMMITMENTS:
      //CACHE count on fact that commitments arrays are usually related to same user or project
      action.payload.commitments.reduce(
        (cache, c) => {
          const { user_id, project_id, date, hour } = c;

          //GET USER INDEX
          const user_index =
            user_id === cache.user_id
              ? cache.user_index
              : draft.users.findIndex((u) => u.id === user_id);
          // console.log('user_index', user_index)

          //GET PROJECT INDEX
          const project_index =
            project_id === cache.project_id
              ? cache.project_index
              : draft.projects.findIndex((p) => p.id === project_id);

          //NOTE commitments should exits! but seems it does not happen always

          const index = draft.users[user_index].commitments[date].findIndex(
            (i) => i.hour === hour
          );

          //REMOVE COMMITMENT
          draft.users[user_index].commitments[date].splice(index, 1);

          //DECREMENT COMMITMENTS COUTNERS
          draft.users[user_index].totalCommitments -= 1;
          draft.projects[project_index].totalCommitments -= 1;
          draft.users[user_index].lastMod = Date.now();
          return {
            user_id,
            project_id,
            user_index,
            project_index,
          };
        },

        {
          user_id: null,
          user_index: null,
          project_id: null,
          project_index: null,
        }
      );
      draft.modTs = Date.now();
      return;
    case PROJECT_UPDATES:
      const { payload: projectUpdates } = action;
      draft.projectUpdates = projectUpdates;
      draft.all_users = getUserInfos(projectUpdates.all_users);
      return;
    case REMOVE_PROJECT_UPDATES:
      draft.projectUpdates = null;
      return;
    case ARCHIVE_PROJECT:
      const { payload: project_archived } = action;
      const toArchiveIndex = draft.projects.findIndex(
        (p) => p.id === project_archived.id
      );
      draft.projects[toArchiveIndex] = project_archived;
      return;
    case CREATE_PROJECT:
      const { payload: project_anew } = action;
      draft.projects.push(project_anew);
      return;
    case UPDATE_PROJECT:
      const { payload: project_update } = action;
      const project_index = draft.projects.findIndex(
        (p) => p.id === project_update.id
      );
      _.keys(project_update).map((k) => {
        draft.projects[project_index][k] = project_update[k];
        return k;
      });
      return;

    case SET_WEEK_DATA:
      // const { users, projects, week } = action.payload
      const { payload: data } = action;
      draft.users = getUsers(data);
      draft.projects = getProjects(data);
      draft.week = getWeek(data);
      draft.notes = getNotes(data);
      draft.loading = false;
      return;
    case SHOW_MENU:
      draft.menu = action.payload;
      return;
    case CLOSE_MENU:
      draft.menu = null;
      return;
    case SIDEBAR:
      draft.sidebar = !draft.sidebar;
      return;
    case FULLSCREEN:
      draft.fullScreen = action.payload.fullScreen;
      return;
    case THEME:
      draft.theme = action.payload.theme;
      return;
    case DRAWER:
      draft.drawer = !draft.drawer;
      return;
    case MODAL:
      if (!action.payload) {
        draft.modal = draft.modal ? false : true;
        draft.modalContent = null;
      } else {
        const { modal, content } = action.payload;
        draft.modal = modal && draft.modal !== modal ? modal : false;
        draft.modalContent = content;
      }
      return;
    case MODAL_CLOSE:
      draft.modal = false;
      draft.modalContent = null;
      return;
    case LOADING:
      draft.loading = true;
      return;
    case DONE:
      draft.loading = false;
      return;
    case CURRENT_PROJECT:
      const { currentProject } = action.payload;
      draft.currentProject = currentProject;

      if (currentProject && draft.sortUser !== 0) {
        draft.sortUser = currentProject.id;
        draft.users = sortUsers(draft, draft.sortUser);
      }
      return;
    case RESET_NOTE:
      draft.currentNote = null;
      return;
    case CURRENT_NOTE:
      const { currentNote } = action.payload;
      draft.currentNote = currentNote;
      if (currentNote) {
        // move note on top or add note
        const notes = draft.notes.filter((n) => n !== currentNote);
        draft.notes = [currentNote, ...notes];
      }
      return;
    case ADD_NOTE:
      const { note } = action.payload;
      draft.notes.unshift(note);
      draft.currentNote = note;
      return;
    default:
      return;
  }
}, initialState);

export default rootReducer;

//HELPERS
export const getWeek = (data) => {
  const { start_date: start, end_date: end } = data;
  return { start, end };
};

export const getProjects = (data) => {
  const projects = data.projects.map((pj) => {
    pj.entries = data.entries.filter((e) => e.project_id === pj.id);
    pj.freckle = data.entries.find((f) => f.id === pj.freckle_projects);

    const minutes = pj.entries.reduce((tot, e) => (tot += e.minutes), 0);
    pj.totalEntries = minutes > 0 ? Math.ceil(minutes / 60) : 0;

    pj.totalCommitments = data.commitments.filter(
      (c) => c.project_id === pj.id
    ).length;
    pj.inactive = pj.totalCommitments === 0 && pj.totalEntries === 0;

    return pj;
  });

  const withoutArchived = projects.filter((p) => p.archived !== true);

  return _.sortBy(withoutArchived, [
    'totalCommitments',
    'totalEntries',
  ]).reverse();
};

export const getNotes = (data) => {
  const notes = data.commitments.map((c) => c.note).filter(Boolean);
  return [...new Set(notes)];
};

export const getUserInfos = (data) => {
  const users = data.map((u) => {
    u.fullName = `${u.first_name} ${u.last_name}`;
    u.initials = `${u.first_name[0]}${u.last_name[0]}`.toUpperCase();

    if (!u.gravatar_hash && u.email) {
      u.gravatar_hash = getGravatarHash(u.email);
    }

    return u;
  });

  return users;
};

export const getUsers = (data) => {
  const users = data.users.map((u) => {
    u.fullName = `${u.first_name} ${u.last_name}`;
    u.initials = `${u.first_name[0]}${u.last_name[0]}`.toUpperCase();

    if (!u.gravatar_hash && u.email) {
      u.gravatar_hash = getGravatarHash(u.email);
    }

    //AVAILABILITY
    const availabilities = data.availabilities.filter(
      (a) => a.user_id === u.id
    );
    const slots = availabilities[0] ? availabilities[0].slots : [];
    u.available = slots.length;
    u.availabilities = [1, 2, 3, 4, 5].reduce((obj, d) => {
      obj[d] = slots.filter((s) => s.weekday === d);
      return obj;
    }, {});

    //ENTRIES
    const entries = data.entries.filter((e) => e.user_id === u.id);
    const minutes = entries.reduce((total, e) => total + e.minutes, 0);
    u.totalEntries = Math.ceil(minutes / 60);

    //COMMITMENTS
    const commitments = data.commitments.filter(
      (c) => parseInt(c.user_id) === u.id
    );
    u.commitments = _.groupBy(commitments, 'date');
    u.totalCommitments = commitments.length;

    //PROJECT STATS
    const projectCommitments = _.groupBy(commitments, 'project_id');
    // console.log('projectCommitments',projectCommitments)
    const projects = _.keys(projectCommitments).map((project_id) => {
      const id = parseInt(project_id);
      const name = data.projects.find((p) => p.id === id).name;
      const totalCommitments = projectCommitments[project_id].length;
      const entry = entries.find((e) => e.project_id === id);
      const totalEntries = entry ? Math.ceil(entry.minutes / 60) : 0;
      return {
        id,
        name,
        totalCommitments,
        totalEntries,
      };
    });
    u.projects = _.sortBy(projects, [
      'totalCommitments',
      'totalEntries',
    ]).reverse();

    return u;
  });
  return _.sortBy(users, ['last_name']);
};

export const sortUsers = (draft, sort) => {
  let users;
  if (sort === 0) {
    users = _.sortBy(draft.users, ['last_name']);
  } else {
    // users = _.sortBy(draft.users, u => {
    //   const found = u.projects.find(p => p.id === draft.currentProject.id)
    //   return found ? -(found.totalEntries + found.totalCommitments) : -1
    // })

    console.log('sort users by project', draft.currentProject.name);

    let usersWithScore = draft.users.map((u) => {
      const found = u.projects.find((p) => p.id === draft.currentProject.id);
      let score = -1;
      if (found) {
        const entries = 0; //found.totalEntries ? found.totalEntries : 0;
        const commitments = found.totalCommitments ? found.totalCommitments : 0;
        score = entries + commitments;
        console.log(u.fullName, score);
      }
      u.lastMod = Date.now();
      return { ...u, score };
    });
    users = _.sortBy(usersWithScore, 'score').reverse();
  }
  return users;
};

export const sortUsersByProject = (draft, userList, currentProjectId) => {
  let users;
  if (currentProjectId === 0) {
    users = _.sortBy(draft.users, ['last_name']);
    return users;
  }
  let usersWithScore = userList.map((u) => {
    const found = u.projects.find((p) => p.id === currentProjectId);
    let score = -1;
    if (found) {
      const entries = 0; //found.totalEntries ? found.totalEntries :
      const commitments = found.totalCommitments ? found.totalCommitments : 0;
      score = entries + commitments;
      console.log(u.fullName, score);
    }
    return { ...u, score };
  });
  users = _.sortBy(usersWithScore, 'score').reverse();
  return users;
};
