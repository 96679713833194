import React, { useRef, memo } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import { labels } from 'labels';
import { selectProject, resetProject, toggleModal } from 'lib/reducers';
// import Icon from './Icon';
import { convertColor } from 'lib/utils';

const mapStateToProps = ({ currentProject }) => ({
  selectedProject: currentProject,
});

const Project = memo((props) => {
  const projectRef = useRef();

  const handleClick = (currentProject) => {
    const { dispatch, selectedProject, onSelected } = props;
    if (selectedProject && currentProject.id === selectedProject.id) {
      dispatch(resetProject());
    } else {
      // console.log('currentProject', currentProject)
      dispatch(selectProject({ currentProject }));
    }
    if (onSelected) {
      onSelected(projectRef.current);
    }
  };

  const handleDetail = (project) => {
    const { dispatch } = props;
    const { id, name } = project;
    dispatch(toggleModal({ modal: 2, content: { name, id } }));
  };

  const { project, selectedProject } = props;
  let cnames = ['project'];
  const isSelected = selectedProject && selectedProject.id === project.id;

  if (isSelected) {
    cnames.push('project--selected');
    console.dir(props);
  } else {
    if (project.archived) {
      cnames.push('project--archived');
    } else if (project.inactive) {
      cnames.push('project--inactive');
    }
  }

  const {
    product_owner,
    budget_consumed_hours,
    budget_hours,
    iteration_consumed_hours,
    iteration_hours,

    inactive,
    archived,
    // totalEntries,
    totalCommitments,
    project_url,
    tracked_url,
  } = project;

  return (
    <article
      ref={projectRef}
      className={`${cnames.join(' ')}`}
      style={{ borderColor: convertColor(project.color) }}
      onClick={() => {
        handleClick(project);
      }}
    >
      <aside className="project__aside">
        <div
          className="project__dot"
          style={{ background: convertColor(project.color) }}
        />
      </aside>
      <section className="project__main">
        <header className="project__header">
          <h2 className="project__title">{project.name}</h2>
          <div className="project__subtitle">
            {!inactive &&
              !archived &&
              product_owner &&
              `${labels.productOwner}  ${product_owner}`}

            {`${
              inactive || archived
                ? archived
                  ? labels.archived
                  : labels.inactive
                : ''
            }`}
          </div>
        </header>
        {isSelected && (
          <div className="project__content">
            {iteration_hours && (
              <div className="project__info">{`${labels.sprint}  ${iteration_consumed_hours}/${iteration_hours}`}</div>
            )}
            {budget_hours && (
              <div className="project__info">{`${labels.budget}  ${budget_consumed_hours}/${budget_hours}`}</div>
            )}
            {!inactive && !archived && (
              <div className="project__info">{` ${labels.hours_committed} ${totalCommitments}`}</div>
            )}

            <ul className="buttons-list">
              <li className="buttons-list__item">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => handleDetail(project)}
                  className="project__link"
                >
                  Updates
                </a>
              </li>
              {project_url && (
                <li className="buttons-list__item">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={project_url}
                    className="project__link"
                  >
                    Chimera
                  </a>
                </li>
              )}
              {tracked_url && (
                <li className="buttons-list__item">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={tracked_url}
                    className="project__link"
                  >
                    Basecamp
                  </a>
                </li>
              )}
            </ul>
          </div>
        )}
      </section>
    </article>
  );
});

export default connect(mapStateToProps)(Project);
