import React, { useState, useEffect } from 'react';
import jwtDecode from 'jwt-decode';

import Home from 'components/Home';
import { me } from 'lib/api';
import { log } from 'lib/utils';
import getToken from 'lib/cookies';

const WrapApp = (props) => {
  const jwt = getToken();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  async function getData(jwt) {
    try {
      const decoded = jwtDecode(jwt);
      log('decoded', decoded);
      // const { user_id } = decoded;
      // persistKey('user_id', user_id);
      // persistKey('token', { bearer_token: jwt, user_id });
      const myinfo = await me();
      log('myinfo', myinfo);
    } catch (error) {
      console.error('ERROR', error);
      setError(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    let timer = setTimeout(() => {
      window.location.href =
        'https://chimera.cantierecreativo.net?redirect_to=rummate';
    }, 7000);
    if (jwt) {
      getData(jwt);
      if (timer) {
        clearTimeout(timer);
      }
    }
  }, [jwt]);

  return <Home error={error} loading={loading} />;
};
export default WrapApp;
